import classNames from 'classnames';
import React from 'react';
import styles from './textInput.module.scss';

export const TextInput = (props) => {
  const {
    className,
    textarea,
    small,
    block,
    errorMessage,
    ...extras
  } = props;

  const Element = textarea ? `textarea` : `input`;
  const inputType = textarea ? false : `text`;

  const classes = classNames(
      styles.input,
      className,
      small && styles.small,
      block && styles.block,
      errorMessage && styles.error,
  );

  return (
    <>
      <Element type={inputType} className={classes} {...extras}>
        {props.children}
      </Element>
      {errorMessage &&
        <div className={styles.errorMessage}>{errorMessage}</div>
      }
    </>
  );
};
