import {Link} from 'gatsby';
import React, {useEffect, useState} from 'react';
import SEO from 'src/components/seo';
import {Button} from 'src/components/ui/button';
import {Card} from 'src/components/ui/card';
import {TextInput} from 'src/components/ui/textInput';
import LogoWithText from 'src/images/logo_light_with_text.svg';
import {api} from 'src/shared/api';
import formStyles from 'src/shared/styles/forms.module.scss';
import {trackPageview} from 'src/shared/utilities/analytics';
import {setUser} from 'src/shared/utilities/session';
import {
  authErrorMessage,
  validateEmail,
  validatePassword,
} from 'src/shared/utilities/validators';

const SignUpPage = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [genericError, setGenericError] = useState(null);

  useEffect(() => {
    trackPageview();
  }, []);

  const resetValidation = () => {
    setEmailError(null);
    setPasswordError(null);
    setGenericError(null);
  };

  const validateForm = () => {
    resetValidation();
    let valid = true;

    const emailError = authErrorMessage(validateEmail(email));
    if (emailError) {
      setEmailError(emailError);
      valid = false;
    }

    const passwordError = authErrorMessage(validatePassword(password));
    if (passwordError) {
      setPasswordError(passwordError);
      valid = false;
    }

    return valid;
  };

  const register = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    api().post('/rest-auth/registration/', {
      username: email,
      email: email,
      password1: password,
      password2: password,
    }).then((response) => {
      setUser(email, {
        // TODO: Use a custom registration endpoint
        token: response.data.key,
      });
      window.location = '/journal';
    }).catch((error) => {
      console.log(error);
      setLoading(false);
      setGenericError('Sign up failed. Do you already have an account'
                      + ' with that email?');
    });
  };

  return (
    <div className={formStyles.authForm}>
      <SEO title='Sign Up' />
      <div className={formStyles.logoWrapper}>
        <Link to="/">
          <img src={LogoWithText} />
        </Link>
      </div>
      <Card>
        <h1 className={formStyles.title}>Sign Up</h1>
        <form>
          <div className={formStyles.inputGroup}>
            <label htmlFor="email">Email</label>
            <TextInput
              block
              name="email"
              value={email}
              errorMessage={emailError}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={formStyles.inputGroup}>
            <label htmlFor="password">Choose a password</label>
            <TextInput
              block
              name="password"
              type="password"
              value={password}
              errorMessage={passwordError}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {genericError &&
            <div className={formStyles.genericError}>
              {genericError}
            </div>
          }
          <Button
            primary
            block
            disabled={loading}
            type="submit"
            className={formStyles.cta}
            onClick={register}>
            Continue
          </Button>
        </form>
      </Card>
      <div className={formStyles.links}>
        <span>Already have an account? </span>
        <Link className={formStyles.authLink} to='/login'>Log in here</Link>
      </div>
    </div>
  );
};

export default SignUpPage;
