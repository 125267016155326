const projectNameMaxLength = 200;

export const errors = {
  PROJECT_NAME_EMPTY: 'PROJECT_NAME_EMPTY',
  PROJECT_NAME_LENGTH: 'PROJECT_NAME_LENGTH',
  EMAIL_EMPTY: 'EMAIL_EMPTY',
  EMAIL_LENGTH: 'EMAIL_LENGTH',
  PASSWORD_EMPTY: 'PASSWORD_EMPTY',
  PASSWORD_LENGTH: 'PASSWORD_LENGTH',
  PASSWORD_CONFIRM_MISMATCH: 'PASSWORD_CONFIRM_MISMATCH',
};

export const validateEmail = (email) => {
  if (email.length === 0) {
    return errors.EMAIL_EMPTY;
  } else if (email.length < 6) {
    return errors.EMAIL_LENGTH;
  }
  return null;
};

export const validatePassword = (password) => {
  if (password.length === 0) {
    return errors.PASSWORD_EMPTY;
  } else if (password.length < 8) {
    return errors.PASSWORD_LENGTH;
  }
  return null;
};

export const validatePasswordConfirmation = (password, confirmation) => {
  if (password !== confirmation) {
    return errors.PASSWORD_CONFIRM_MISMATCH;
  }
  return null;
};

export const validateProjectName = (name) => {
  if (!name || name.length === 0) {
    return 'Projects need a name';
  } else if (name.length > projectNameMaxLength) {
    return `Project names must be less than ${projectNameMaxLength} characters`;
  }
  return null;
};

export const authErrorMessage = (errorConstant) => {
  switch (errorConstant) {
    case errors.EMAIL_EMPTY:
      return 'Who are you?';
    case errors.EMAIL_LENGTH:
      return 'That doesn\'t look like an email address';
    case errors.PASSWORD_EMPTY:
      return 'You need this';
    case errors.PASSWORD_LENGTH:
      return 'That\'s not long enough (min 8 characters)';
    case errors.PASSWORD_CONFIRM_MISMATCH:
      return 'These passwords don\'t match';
    default:
      return null;
  }
};

export default {
  validateProjectName,
};
